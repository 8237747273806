import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const heroImage = $('#headerHeroImage');

// Calculate the full width of the element
const fullWidth = heroImage.offsetWidth;

function backgroundLine(){
    var backTimeline = gsap.timeline();
    backTimeline.from("#headerHeroImage", {duration: 3, opacity: 0, ease: "power2.out"})
    .from("#footerText", {duration: 3, y: -20, opacity: 0, ease: "power2.out"})
    .from("#headerHeroImage", {duration: 30, scale:2, yoyo: true, repeat:-1, ease: "ease.in"}, "-=5");
    return backTimeline;
}


function contentLine(){
    var contentTimeline = gsap.timeline();
    contentTimeline.from("#mainContent h2", {duration: 0.75, x: +20, opacity: 0, ease: "power2.out"})
               .from("#mainContent li", {duration: 1, y:-10, opacity: 0, stagger: 0.25, ease: "power2.out"})
               .from("#mainContent .down-arrow", {duration: 1, y:-10, opacity: 0, ease: "power2.out"}, "-=0.5")
               .from("#mainContent .contact-info > *", {duration: 1, y:10, opacity: 0, stagger: 0.25, ease: "power2.out"});
    return contentTimeline;
}

function logoLine(){
    var logoTimeline = gsap.timeline({delay:1});
    logoTimeline.from("#nextText", {duration: 0.75, y: -20, opacity: 0, ease: "power2.out"})
            .from("#levelText", {duration: 0.75, x: +20, opacity: 0}, "-=0.75")
            .from("#distinctMediaDevelopmentText", {duration: 0.75, y: +10, opacity: 0})
            .from("#logoArrowImage", {duration: 1.6, y: +20, opacity: 0,  ease: "power4.out" }, "-=0.5")
            .to("#arrowPath, #levelText path", {duration: .1, fill: '#008cff', ease: "power4.out"}, "-=0.7")
            .to("#arrowPath, #levelText path", {duration: .7, delay: 0.1, fill: '#0069bd'});
    return logoTimeline;
}

var masterTimeline = gsap.timeline();
masterTimeline.add(logoLine())
                .add(contentLine(), "-=1")
                .add(backgroundLine(), "-=5");
                


$(window).bind('keydown', function(event) {
    if (event.ctrlKey || event.metaKey) {
        switch (String.fromCharCode(event.which).toLowerCase()) {
        case 'g':
            event.preventDefault();
            $('.responsive-info').css("visibility", "visible");
            break;
        }
    }
}).on('resize', function () {
    $(".screen-size").text($(window).width() + "px");
});


